<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>Gold Standard Time vs Actual Time Spent</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <div
            v-if="chart"
            class="chart"
          >
            <vue-apex-charts
              type="bar"
              height="350"
              :options="chart.chartOptions"
              :series="chart.series"
            />
            <!-- <div class="text-center">
              <b-card-text>Total Time Spent: <b>{{ totalActualTimeSpent }} minutes ({{ totalUnitsTimeSpent }} units)</b></b-card-text>
              <b-card-text>Total Gold Standard Time: <b>{{ totalGoldStandardTime }} minutes ({{ totalUnitsGoldStandardTime }} units)</b></b-card-text>
            </div> -->
            <vs-alert
              v-if="totalUnitsTimeSpent > totalUnitsGoldStandardTime"
              class="mt-5"
              icon-pack="feather"
              icon="icon-info"
            >
              <p>You have spent an excess of <b>{{ totalUnitsTimeSpent - totalUnitsGoldStandardTime }}</b> unit(s) of time. Consider reviewing any obstacles you are facing that could be causing you to spend too much time.</p>
            </vs-alert>
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  data() {
    return {
      themeColors: ['#FFD700', '#000080', '#EA5455', '#FF9F43', '#1E1E1E'],
      themeColorsAlt: ['#184fa3', '#EA5455'],
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    // totalGoldStandardTime() {
    //   return moment.duration(this.getTotalGoldStandardTime()).asMinutes()
    // },
    // totalActualTimeSpent() {
    //   return moment.duration(this.getTotalActualTime()).asMinutes()
    // },
    totalUnitsTimeSpent() {
      let units = 0
      try {
        const { actual_vs_gold_standard_time } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        if (actual_vs_gold_standard_time) {
          const values = Object.values(actual_vs_gold_standard_time)
          // eslint-disable-next-line camelcase
          units = values.reduce((n, { total_actual_time_in_units }) => n + total_actual_time_in_units, 0)
        }
      } catch (e) {
        console.debug(`Error in totalUnitsTimeSpent: ${e.message}`)
      }
      return units
    },
    totalUnitsGoldStandardTime() {
      let units = 0
      try {
        const { actual_vs_gold_standard_time } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        if (actual_vs_gold_standard_time) {
          const values = Object.values(actual_vs_gold_standard_time)
          // eslint-disable-next-line camelcase
          units = values.reduce((n, { total_gold_standard_time_in_units }) => n + total_gold_standard_time_in_units, 0)
        }
      } catch (e) {
        console.debug(`Error in totalUnitsTimeSpent: ${e.message}`)
      }
      return units
    },
    chart() {
      let options = null
      try {
        const { actual_vs_gold_standard_time } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        if (actual_vs_gold_standard_time) {
          const values = Object.values(actual_vs_gold_standard_time)
          const actualTimes = values.map(v => v.total_actual_time_in_mins)
          const goldStandardTimes = values.map(v => v.total_gold_standard_time_in_mins)
          const labels = values.map(v => v.label)
          options = {
            series: [{
              name: 'Gold Standard Time',
              data: goldStandardTimes,
            }, {
              name: 'Actual Time Spent',
              data: actualTimes,
            }],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: 'top',
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              colors: this.themeColors,
              xaxis: {
                categories: labels,
                title: {
                  text: 'Time in minutes',
                },
              },
              yaxis: {
                title: {
                  text: 'Services Performed',
                },
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Error in GoldStandardTimeBarChart.vue ${e.message}`)
      }
      return options
    },
  },
  methods: {
    getTotalGoldStandardTime() {
      let output = moment().startOf('day').seconds(0).format('HH:mm:ss')
      try {
        const { services_performed } = this.currentDashboardAnalytics
        if (services_performed.length > 0) {
          const gst = services_performed.map(g => moment.duration(g.gold_standard_time).asSeconds())
          const sum = gst.reduce((a, b) => a + b, 0)
          output = moment().startOf('day').seconds(sum).format('HH:mm:ss')
        }
      } catch (e) {
        console.debug(`Error in getTotalGoldStandardTime: ${e.message}`)
      }
      return output
    },
    getTotalActualTime() {
      let output = moment().startOf('day').seconds(0).format('HH:mm:ss')
      try {
        const { services_performed } = this.currentDashboardAnalytics
        if (services_performed.length > 0) {
          const ast = services_performed.map(a => moment.duration(a.actual_time_spent).asSeconds())
          const sum = ast.reduce((a, b) => a + b, 0)
          output = moment().startOf('day').seconds(sum).format('HH:mm:ss')
        }
      } catch (e) {
        console.debug(`Error in getTotalActualTime: ${e.message}`)
      }
      return output
    },
    unitsOfTime(timestamp, mins = null) {
      let minutes
      if (mins) {
        minutes = mins
      } else {
        minutes = moment.duration(timestamp).asMinutes()
      }
      const cursor = Math.floor(minutes / 15)
      let units = 0
      if (cursor === 0) {
        if (minutes <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      } else {
        units = cursor
        const diff = minutes - (15 * cursor)
        if (diff <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      }
      return units
    },
    unitsOfTimeFormatted(timestamp) {
      const units = this.unitsOfTime(timestamp)
      if (units <= 1) {
        return `${units} unit`
      }

      return `${units} units`
    },
  },
}
</script>
