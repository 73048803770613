<template>
  <section id="dashboard-user">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <welcome-card />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <quick-links />
      </b-col>
    </b-row>
    <b-row
      v-if="noData"
      class="match-height"
    >
      <b-col
        lg="12"
        md="12"
      >
        <no-data />
      </b-col>
    </b-row>
    <div v-else>
      <!-- <b-card-group> -->
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <happiness-rating />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <pie-donut-chart
            title="Responses to 'Your day was stressful'."
            :chart-data="stressTallyData"
            chart-type="pie"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <bar-chart
            title="Responses to 'Were there staff issues that needed to be addressed?'"
            :chart-data="staffIssuesData"
            y-axis-title="Number of responses"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <yes-no-count
            title="Responses to 'Are you happy with your production and your procedures that you completed today?'"
            :chart-data="satisfactionData"
          />
        </b-col>
      </b-row>
      <!-- </b-card-group> -->
    </div>
  </section>
</template>

<script>
import { BRow, BCol, BCardGroup } from 'bootstrap-vue'
import WelcomeCard from '@/views/dashboard/cards/WelcomeCard.vue'
import QuickLinks from '@/views/dashboard/cards/QuickLinks.vue'
import NoData from '@/views/dashboard/cards/NoData.vue'
import HappinessRating from '@/views/dashboard/cards/HappinessRating.vue'
import PieDonutChart from '@/views/dashboard/cards/PieDonutChart.vue'
import BarChart from '@/views/dashboard/cards/BarChart.vue'
import YesNoCount from '@/views/dashboard/cards/YesNoCount.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardGroup,
    WelcomeCard,
    QuickLinks,
    NoData,
    HappinessRating,
    PieDonutChart,
    BarChart,
    YesNoCount,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    noData() {
      let isEmptyorNull = true
      if (this.currentDashboardAnalytics) {
        // {"analytics":{"top_other_services":[],"top_obstacles":[],"number_of_clients_seen":[],"number_of_cancellations":[],"late_frequency":[],"support_level":[]}}
        const keysLength = Object.entries(this.currentDashboardAnalytics).length
        let count = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.currentDashboardAnalytics)) {
          if (key && value.length === 0) {
            count += 1
          }
        }
        if (keysLength !== count) {
          isEmptyorNull = false
        }
      }
      return isEmptyorNull
    },
    stressTallyData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { stress_tally } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = stress_tally
      }
      return data
    },
    staffIssuesData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { staff_issues } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = staff_issues
      }
      return data
    },
    satisfactionData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { production_procedure_satisfaction_tally } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = production_procedure_satisfaction_tally
      }
      return data
    },
  },
  async created() {
    await this.getDashboardAnalytics(this.activeUser)
  },
  methods: {
    ...mapActions('statistics', ['getDashboardAnalytics']),
  },
}
</script>
