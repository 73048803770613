<template>
  <section id="dashboard-user">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <welcome-card />
      </b-col>
    </b-row>
    <b-row
      v-if="noData"
      class="match-height"
    >
      <b-col
        lg="12"
        md="12"
      >
        <no-data />
      </b-col>
    </b-row>
    <div v-else>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
          <top-obstacles />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="3"
          md="3"
        >
          <performance-rating title="Overall Average Performance Rating" />
        </b-col>
        <b-col
          lg="5"
          md="5"
        >
          <new-users />
        </b-col>
        <b-col
          lg="4"
          md="4"
        >
          <total-users />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <recommended-toothbrushes />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <services-talked-about />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import WelcomeCard from '@/views/dashboard/cards/WelcomeCard.vue'
import NoData from '@/views/dashboard/cards/NoData.vue'
import TopObstacles from '@/views/dashboard/cards/TopObstacles.vue'
import PerformanceRating from '@/views/dashboard/cards/PerformanceRating.vue'
import RecommendedToothbrushes from '@/views/dashboard/cards/RecommendedToothbrushes.vue'
import ServicesTalkedAbout from '@/views/dashboard/cards/ServicesTalkedAbout.vue'
import NewUsers from '@/views/dashboard/cards/NewUsers.vue'
import TotalUsers from '@/views/dashboard/cards/TotalUsers.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    WelcomeCard,
    NoData,
    TopObstacles,
    PerformanceRating,
    RecommendedToothbrushes,
    ServicesTalkedAbout,
    NewUsers,
    TotalUsers,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    noData() {
      let isEmptyorNull = true
      if (this.currentDashboardAnalytics) {
        // {"analytics":{"top_other_services":[],"top_obstacles":[],"number_of_clients_seen":[],"number_of_cancellations":[],"late_frequency":[],"support_level":[]}}
        const keysLength = Object.entries(this.currentDashboardAnalytics).length
        let count = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.currentDashboardAnalytics)) {
          if (key && value.length === 0) {
            count += 1
          }
        }
        if (keysLength !== count) {
          isEmptyorNull = false
        }
      }
      return isEmptyorNull
    },
  },
  async created() {
    await this.getDashboardAnalytics(this.activeUser)
  },
  methods: {
    ...mapActions('statistics', ['getDashboardAnalytics']),
  },
}
</script>
