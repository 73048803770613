<template>
  <b-card
    text-variant="center"
    body-class="align-middle"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2">
        {{ subtitle }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="align-middle">
      <b-row
        v-if="chart"
        align-h="center"
      >
        <b-col
          cols="6"
          align-self="center"
        >
          <div
            class="chart"
          >
            <h1
              class="mt-1"
              style="font-size:7em"
            >
              {{ chart.yes_score }}
            </h1>

            <h1 class="mt-1">
              <b-avatar
                size="50"
                :variant="chart.yes_colour"
              >
                <feather-icon
                  size="50"
                  :icon="chart.yes_icon"
                />
              </b-avatar>
            </h1>
            <h1>Yes</h1>
          </div>
        </b-col>
        <b-col
          cols="6"
          align-self="center"
        >
          <div
            class="chart"
          >
            <h1
              class="mt-1"
              style="font-size:7em"
            >
              {{ chart.no_score }}
            </h1>

            <h1 class="mt-1">
              <b-avatar
                size="50"
                :variant="chart.no_colour"
              >
                <feather-icon
                  size="50"
                  :icon="chart.no_icon"
                />
              </b-avatar>
            </h1>
            <h1>No</h1>
          </div>
        </b-col>
        <b-card-title class="mt-5">
          {{ description }}
        </b-card-title>
      </b-row>
      <div v-else>
        <b-col
          cols="12"
          align-self="center"
        >
          <b-skeleton-img />
          <b-card-text class="mt-2">
            Awaiting data...
          </b-card-text>
        </b-col>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    BSkeletonImg,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Last 30 days',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    chart() {
      let payload = null
      try {
        if (this.chartData) {
          payload = {}
          payload.yes_colour = 'success'
          payload.yes_icon = 'ThumbsUpIcon'
          payload.yes_score = this.chartData.yes
          payload.no_colour = 'danger'
          payload.no_icon = 'ThumbsDownIcon'
          payload.no_score = this.chartData.no
        }
      } catch (e) {
        console.debug(`Error in YesNoCount.vue: ${e.message}`)
      }
      return payload
    },
  },
}
</script>
