<template>
  <dental-hygienist-layout v-if="activeUser.currentRole.name === 'dental_hygienist'" />
  <dental-assistant-layout v-else-if="activeUser.currentRole.name === 'dental_assistant'" />
  <office-manager-layout v-else-if="activeUser.currentRole.name === 'office_manager'" />
  <treatment-coordinator-layout v-else-if="activeUser.currentRole.name === 'treatment_coordinator'" />
  <receptionist-layout v-else-if="activeUser.currentRole.name === 'receptionist'" />
  <dentist-layout v-else-if="activeUser.currentRole.name === 'dentist'" />
  <dentist-associate-layout v-else-if="activeUser.currentRole.name === 'dentist_associate'" />
  <admin-layout v-else-if="activeUser.currentRole.name === 'admin'" />
</template>

<script>
import DentalHygienistLayout from '@/views/dashboard/layouts/DentalHygienistLayout.vue'
import DentalAssistantLayout from '@/views/dashboard/layouts/DentalAssistantLayout.vue'
import OfficeManagerLayout from '@/views/dashboard/layouts/OfficeManagerLayout.vue'
import TreatmentCoordinatorLayout from '@/views/dashboard/layouts/TreatmentCoordinatorLayout.vue'
import ReceptionistLayout from '@/views/dashboard/layouts/ReceptionistLayout.vue'
import DentistLayout from '@/views/dashboard/layouts/DentistLayout.vue'
import AdminLayout from '@/views/dashboard/layouts/AdminLayout.vue'
import DentistAssociateLayout from '@/views/dashboard/layouts/DentistAssociateLayout.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    DentalHygienistLayout,
    DentalAssistantLayout,
    OfficeManagerLayout,
    TreatmentCoordinatorLayout,
    ReceptionistLayout,
    DentistLayout,
    AdminLayout,
    DentistAssociateLayout,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
  },
}
</script>
