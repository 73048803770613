<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>Top Obstacles</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <vue-apex-charts
            v-if="topObstacles"
            type="donut"
            height="350"
            :options="topObstacles.chartOptions"
            :series="topObstacles.series"
          />
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
// import moment from 'moment'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  data() {
    return {
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      themeColorsAlt: ['#184fa3', '#EA5455'],
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    topObstacles() {
      let options = null
      try {
        const { top_obstacles } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        if (top_obstacles && top_obstacles.length > 0) {
          options = {
            // eslint-disable-next-line radix
            series: top_obstacles.map(o => parseInt(o.occurrences)),
            chartOptions: {
              labels: top_obstacles.map(o => o.description),
              colors: this.themeColors,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: '100%',
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              }],
              legend: {
                position: 'bottom',
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Could not fetch top obstacles: ${e.message}`)
      }
      return options
    },
  },
}
</script>
