<template>
  <b-card
    text-variant="center"
    body-class="align-middle"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body class="align-middle">
      <b-row align-h="center">
        <b-col
          cols="12"
          align-self="center"
        >
          <div
            v-if="performance"
            class="chart"
          >
            <h1
              class="mt-1"
              style="font-size:7em"
            >
              {{ performance.score }}
            </h1>

            <h1 class="mt-1">
              {{ performance.grade }}
              <b-avatar
                size="50"
              >
                <feather-icon
                  size="50"
                  :icon="performance.icon"
                />
              </b-avatar>
            </h1>
            <b-card-title class="mt-5">
              Average happiness score is calculated based on your responses where happiness at work was assessed on a scale from 1 to 10.
            </b-card-title>
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable camelcase */
import {
  BCard, BAvatar, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    BSkeletonImg,
  },
  props: {
    title: {
      type: String,
      default: 'Your Average Happiness Score',
      required: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    performance() {
      let performance = null
      try {
        const { average_happiness } = this.currentDashboardAnalytics
        if (average_happiness) {
          performance = {}
          if (average_happiness.avg_value >= 7 && average_happiness.avg_value <= 10) {
            performance.grade = 'Happy'
            performance.colour = 'success'
            performance.icon = 'SmileIcon'
            performance.score = average_happiness.avg_value.toFixed(1)
          } else if (average_happiness.avg_value >= 4 && average_happiness.avg_value < 7) {
            performance.grade = 'Neutral'
            performance.colour = 'warning'
            performance.icon = 'MehIcon'
            performance.score = average_happiness.avg_value.toFixed(1)
          } else {
            performance.grade = 'Unhappy'
            performance.colour = 'danger'
            performance.icon = 'FrownIcon'
            performance.score = average_happiness.avg_value.toFixed(1)
          }
        }
      } catch (e) {
        console.debug(`Error calculating happiness in HappinessRating.vue: ${e.message}`)
      }
      return performance
    },
  },
}
</script>
