<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>Recommended Toothbrushes</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <div
            v-if="topToothbrushes"
            class="chart"
          >
            <vue-apex-charts
              type="bar"
              height="350"
              :options="topToothbrushes.chartOptions"
              :series="topToothbrushes.series"
            />
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  data() {
    return {
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      themeColorsAlt: ['#184fa3', '#EA5455'],
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    topToothbrushes() {
      let options = null
      try {
        const { toothbrush_stats } = this.currentDashboardAnalytics
        if (toothbrush_stats.length > 0) {
          options = {
            series: [{
              // eslint-disable-next-line radix
              data: toothbrush_stats.map(o => parseInt(o.occurrences)),
            }],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: toothbrush_stats.map(o => o.name),
                title: {
                  text: 'Number of recommendations',
                },
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Error getting toothbrush data in Recommended Toothbrushes.vue: ${e.message}`)
      }
      return options
    },
  },
  methods: {
  },
}
</script>
