<template>
  <b-card
    text-variant="center"
    body-class="align-middle"
  >
    <b-card-header>
      <b-card-title>New Users</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body class="align-middle">
      <b-row align-h="center">
        <b-col
          cols="12"
          align-self="center"
        >
          <div
            v-if="newUsers"
            class="chart"
          >
            <b-avatar
              size="100"
              variant="primary"
            >
              <feather-icon
                size="100"
                icon="UserIcon"
              />
            </b-avatar>
            <h1 class="mt-1 text-large">
              {{ newUsers }}
            </h1>
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    BSkeletonImg,
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    newUsers() {
      let number = null
      try {
        const { new_users } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        number = new_users
      } catch (e) {
        console.debug(`Error in newUsers(): ${e.message}`)
      }
      return number
    },
  },
}
</script>

<style lang="scss">
  .text-large {
    font-size: 5em;
  }
</style>
