<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2">
        {{ subtitle }}
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <div
            v-if="chart"
            class="chart"
          >
            <vue-apex-charts
              type="bar"
              :height="chartHeight"
              :options="chart.chartOptions"
              :series="chart.series"
            />
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    themeColors: {
      type: Array,
      required: false,
      default: () => ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Last 30 days',
    },
    chartHeight: {
      type: String,
      required: false,
      default: '350',
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    xAxisTitle: {
      type: String,
      required: false,
      default: '',
    },
    yAxisTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    chart() {
      let options = null
      try {
        if (this.chartData) {
          const { labels, ...payload } = this.chartData
          options = {
            series: [{
              name: 'Responses',
              // eslint-disable-next-line radix
              data: Object.values(payload),
            }],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: this.horizontal,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: labels,
                title: {
                  text: this.xAxisTitle,
                },
              },
              yaxis: {
                logarithmic: false,
                tickAmount: 1,
                title: {
                  text: this.yAxisTitle,
                },
                labels: {
                  // eslint-disable-next-line no-unused-vars
                  formatter(val, index) {
                    return val.toFixed(0)
                  },
                },
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Error in BarChart.vue: ${e.message}`)
      }
      return options
    },
  },
  methods: {
  },
}
</script>
