<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>Quick Links</b-card-title>
      <!-- <b-card-text class="font-small-2">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          v-if="$can('create', 'User')"
          sm="auto"
        >
          <b-button
            variant="primary"
            class="mt-1"
            :to="{ name: 'users' }"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            {{ $t('buttons.manage_users') }}
          </b-button>
        </b-col>
        <b-col
          v-if="$can('create', 'LoggableItem')"
          sm="auto"
        >
          <b-button
            variant="primary"
            class="mt-1"
            :to="{ name: 'log-entries-new' }"
          >
            <feather-icon
              icon="FilePlusIcon"
              class="mr-50"
            />
            New Log Entry
          </b-button>
        </b-col>
        <b-col
          v-if="$can('read', 'Resource')"
          sm="auto"
        >
          <b-button
            class="mt-1"
            variant="primary"
            :to="{ name: 'learning-resources' }"
          >
            <feather-icon
              icon="BookIcon"
              class="mr-50"
            />
            Learning Resources
          </b-button>
        </b-col>
        <b-col
          v-if="activeUser.roles && activeUser.roles.length > 1 && $can('switch', 'Role')"
          sm="auto"
        >
          <b-button
            v-b-modal.switch-role-modal
            class="mt-1"
            variant="primary"
          >
            <feather-icon
              icon="RepeatIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('page_titles.switch_role') }}</span>
          </b-button>
        </b-col>
        <b-col
          v-if="activeUser && activeUser.state && activeUser.state === 'activated' && $can('access_patient_portal', 'Office')"
          sm="auto"
        >
          <b-button
            class="mt-1"
            variant="primary"
            @click="patientPortalHandler"
          >
            <feather-icon
              icon="ArrowRightIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('page_titles.patient_portal') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          sm="auto"
        >
          <b-alert
            v-height-fade.appear
            show
            class="mt-5"
            variant="secondary"
            style="max-height: 600px"
          >
            <div
              class="alert-body text-left"
            >
              <!-- <feather-icon
                icon="InfoIcon"
                class="mr-50"
              /> -->
              <p v-if="$can('create', 'User')">
                <strong>Manage Users</strong> - The Manage Users page is where you can add new office members, assign multiple roles (duties) to office members, reset passwords and deactivate office members.
              </p>
              <p v-if="$can('create', 'LoggableItem')">
                <strong>New Log Entry</strong> - The New Log Entry page is where you can start submitting your data.
              </p>
              <p v-if="$can('read', 'Resource')"><strong>Learning Resources</strong> - The Learning Resources page offers you a collection of curated digital learning resources designed to empower you and enhance your knowledge.</p>
              <p v-if="activeUser.roles && activeUser.roles.length > 1 && $can('switch', 'Role')">
                <strong>Switch Role</strong> - Are you working under multiple roles (duties)? Click this button to switch your current role in DenTyme.
              </p>
              <p v-if="activeUser && activeUser.state && activeUser.state === 'activated' && $can('access_patient_portal', 'Office')">
                <strong>Patient Portal</strong> - Quick access to the Patient Portal which is designed to anonymously accept feedback from patients at your office!
              </p>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, VBModal, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import {
  heightFade,
} from '@core/directives/animations'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    'height-fade': heightFade,
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
    ...mapActions('office', ['accessPatientPortal']),
    async patientPortalHandler() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You will be signed out and redirected to the patient portal.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, take me there!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.loading = true
        try {
          if (result.value) {
            const requestObject = {
              office_id: this.activeUser.office_id,
            }
            await this.accessPatientPortal(requestObject).then(() => {
              this.$router.go()
            })
          }
        } catch (error) {
          console.debug(`Patient Portal handler error in QuickLinks.vue: ${error.message}`)
          this.$swal({
            title: 'Error!',
            icon: 'error',
            html: this.$t('errors.generic'),
            buttonsStyling: true,
          })
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
