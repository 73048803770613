<template>
  <section id="dashboard-user">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <welcome-card />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <quick-links />
      </b-col>
    </b-row>
    <b-row
      v-if="noData"
      class="match-height"
    >
      <b-col
        lg="12"
        md="12"
      >
        <no-data />
      </b-col>
    </b-row>
    <div v-else>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <happiness-rating />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <total-count
            title="Total number of patients that were concerned about insurance coverage"
            :chart-data="insuranceCoverageData"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <pie-donut-chart
            title="Top obstacles faced"
            :chart-data="obstacleData"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <total-count
            title="Total number of calls made for outstanding treatment"
            :chart-data="outstandingTreatmentData"
          />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import WelcomeCard from '@/views/dashboard/cards/WelcomeCard.vue'
import QuickLinks from '@/views/dashboard/cards/QuickLinks.vue'
import NoData from '@/views/dashboard/cards/NoData.vue'
import HappinessRating from '@/views/dashboard/cards/HappinessRating.vue'
import PieDonutChart from '@/views/dashboard/cards/PieDonutChart.vue'
import TotalCount from '@/views/dashboard/cards/TotalCount.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    WelcomeCard,
    QuickLinks,
    NoData,
    HappinessRating,
    PieDonutChart,
    TotalCount,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    noData() {
      let isEmptyorNull = true
      if (this.currentDashboardAnalytics) {
        // {"analytics":{"top_other_services":[],"top_obstacles":[],"number_of_clients_seen":[],"number_of_cancellations":[],"late_frequency":[],"support_level":[]}}
        const keysLength = Object.entries(this.currentDashboardAnalytics).length
        let count = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.currentDashboardAnalytics)) {
          if (key && value.length === 0) {
            count += 1
          }
        }
        if (keysLength !== count) {
          isEmptyorNull = false
        }
      }
      return isEmptyorNull
    },
    insuranceCoverageData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { insurance_coverage } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = insurance_coverage
      }
      return data
    },
    outstandingTreatmentData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { outstanding_treatment } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = outstanding_treatment
      }
      return data
    },
    obstacleData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { obstacles_faced } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = obstacles_faced
      }
      return data
    },
  },
  async created() {
    await this.getDashboardAnalytics(this.activeUser)
  },
  methods: {
    ...mapActions('statistics', ['getDashboardAnalytics']),
  },
}
</script>
