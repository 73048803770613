<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
      :src="require('@/assets/images/logo/dentyme-logo.png')"
    />
    <h1 class="mb-1 mt-50 text-white">
      Hello, {{ activeUser.name }}!
    </h1>
    <b-card-text class="m-auto w-75 text-white">
      <p>Welcome to <span class="logo-text-white">DenTyme</span>! As you log data daily, this page will provide you with an updated insight around your performance.</p>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardText,
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
}
</script>
