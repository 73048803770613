<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2">
        {{ subtitle }}
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <div
            v-if="chart"
            class="chart"
          >
            <vue-apex-charts
              :type="chartType"
              :height="chartHeight"
              :options="chart.chartOptions"
              :series="chart.series"
            />
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    themeColors: {
      type: Array,
      required: false,
      default: () => ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Last 30 days',
    },
    chartType: {
      type: String,
      required: false,
      default: 'pie',
    },
    chartHeight: {
      type: String,
      required: false,
      default: '350',
    },
    legendPosition: {
      type: String,
      required: false,
      default: 'bottom',
    },
  },
  data() {
    return {}
  },
  computed: {
    chart() {
      let options = null
      try {
        if (this.chartData) {
          const { labels, ...payload } = this.chartData
          options = {
            series: Object.values(payload),
            chartOptions: {
              labels,
              colors: this.themeColors,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: this.legendPosition,
                  },
                },
              }],
              legend: {
                position: this.legendPosition,
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Error in PieChart.vue: ${e.message}`)
      }
      return options
    },
  },
  methods: {
  },
}
</script>
