<template>
  <section id="dashboard-user">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <welcome-card />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <quick-links />
      </b-col>
    </b-row>
    <b-row
      v-if="noData"
      class="match-height"
    >
      <b-col
        lg="12"
        md="12"
      >
        <no-data />
      </b-col>
    </b-row>
    <div v-else>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <happiness-rating />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <pie-donut-chart
            title="Top Obstacles Faced"
            :chart-data="obstacleData"
            chart-type="pie"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          lg="6"
          md="6"
        >
          <pie-donut-chart
            title="Top Services Performed"
            :chart-data="servicesData"
            chart-type="donut"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <gold-standard-time-bar-chart />
        </b-col>
        <!-- <b-col
          lg="4"
          md="4"
        >
          <services-talked-about />
        </b-col> -->
      </b-row>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import WelcomeCard from '@/views/dashboard/cards/WelcomeCard.vue'
import QuickLinks from '@/views/dashboard/cards/QuickLinks.vue'
import NoData from '@/views/dashboard/cards/NoData.vue'
import TopObstacles from '@/views/dashboard/cards/TopObstacles.vue'
import GoldStandardTime from '@/views/dashboard/cards/GoldStandardTime.vue'
import PerformanceRating from '@/views/dashboard/cards/PerformanceRating.vue'
import RecommendedToothbrushes from '@/views/dashboard/cards/RecommendedToothbrushes.vue'
import ServicesTalkedAbout from '@/views/dashboard/cards/ServicesTalkedAbout.vue'
import HappinessRating from '@/views/dashboard/cards/HappinessRating.vue'
import PieDonutChart from '@/views/dashboard/cards/PieDonutChart.vue'
import GoldStandardTimeBarChart from '@/views/dashboard/cards/GoldStandardTimeBarChart.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    WelcomeCard,
    QuickLinks,
    NoData,
    // eslint-disable-next-line vue/no-unused-components
    TopObstacles,
    // eslint-disable-next-line vue/no-unused-components
    GoldStandardTime,
    // eslint-disable-next-line vue/no-unused-components
    PerformanceRating,
    // eslint-disable-next-line vue/no-unused-components
    RecommendedToothbrushes,
    // eslint-disable-next-line vue/no-unused-components
    ServicesTalkedAbout,
    HappinessRating,
    PieDonutChart,
    GoldStandardTimeBarChart,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    noData() {
      let isEmptyorNull = true
      if (this.currentDashboardAnalytics) {
        // {"analytics":{"top_other_services":[],"top_obstacles":[],"number_of_clients_seen":[],"number_of_cancellations":[],"late_frequency":[],"support_level":[]}}
        const keysLength = Object.entries(this.currentDashboardAnalytics).length
        let count = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.currentDashboardAnalytics)) {
          if (key && value.length === 0) {
            count += 1
          }
        }
        if (keysLength !== count) {
          isEmptyorNull = false
        }
      }
      return isEmptyorNull
    },
    obstacleData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { obstacles_faced } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = obstacles_faced
      }
      return data
    },
    servicesData() {
      let data = {}
      if (this.currentDashboardAnalytics) {
        const { services_performed } = this.currentDashboardAnalytics
        // eslint-disable-next-line camelcase
        data = services_performed
      }
      return data
    },
  },
  async created() {
    await this.getDashboardAnalytics(this.activeUser)
  },
  methods: {
    ...mapActions('statistics', ['getDashboardAnalytics']),
  },
}
</script>
