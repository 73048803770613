<template>
  <b-card
    text-variant="center"
  >
    <b-card-header>
      <b-card-title>Gold Standard Time vs Actual Time Spent</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body>
      <b-row align-h="center">
        <b-col
          cols="12"
        >
          <div
            v-if="servicesPerformed"
            class="chart"
          >
            <vue-apex-charts
              type="bar"
              height="350"
              :options="servicesPerformed.lineAreaChartSpline.chartOptions"
              :series="servicesPerformed.lineAreaChartSpline.series"
            />
            <div class="text-center">
              <b-card-text>Total Time Spent: <b>{{ totalActualTimeSpent }} minutes ({{ totalUnitsTimeSpent }} units)</b></b-card-text>
              <b-card-text>Total Gold Standard Time: <b>{{ totalGoldStandardTime }} minutes ({{ totalUnitsGoldStandardTime }} units)</b></b-card-text>
            </div>
            <!-- <vs-alert
              v-if="totalUnitsTimeSpent > totalUnitsGoldStandardTime"
              class="mt-5"
              icon-pack="feather"
              icon="icon-info"
            >
            <p>You have spent an excess of <b>{{ totalUnitsTimeSpent - totalUnitsGoldStandardTime }}</b> unit(s) of time. Consider reviewing any obstacles you are facing that could be causing you to spend too much time.</p>
            </vs-alert> -->
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    BSkeletonImg,
  },
  data() {
    return {
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      themeColorsAlt: ['#184fa3', '#EA5455'],
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    totalGoldStandardTime() {
      return moment.duration(this.getTotalGoldStandardTime()).asMinutes()
    },
    totalActualTimeSpent() {
      return moment.duration(this.getTotalActualTime()).asMinutes()
    },
    totalUnitsTimeSpent() {
      let units = 0
      try {
        const { performance } = this.currentDashboardAnalytics
        if (performance) {
          units = this.unitsOfTime(0, this.totalActualTimeSpent)
        }
      } catch (e) {
        console.debug(`Error in totalUnitsTimeSpent: ${e.message}`)
      }
      return units
    },
    totalUnitsGoldStandardTime() {
      let units = 0
      try {
        const { performance } = this.currentDashboardAnalytics
        if (performance) {
          units = this.unitsOfTime(0, this.totalGoldStandardTime)
        }
      } catch (e) {
        console.debug(`Error in totalUnitsTimeSpent: ${e.message}`)
      }
      return units
    },
    servicesPerformed() {
      let options = null
      try {
        const { services_performed } = this.currentDashboardAnalytics
        if (services_performed.length > 0) {
          // let xAxis = Array.from(new Set(this.currentDashboardAnalytics.analytics.services_performed.map(o => moment(o.submitted_at).format('MMM D'))));
          const dates = Array.from(new Set(services_performed.map(o => moment(o.submitted_at).valueOf()).sort()))
          const xAxis = [...new Set(dates.map(o => moment(o).format('MMM D')))]
          const gst = [] // total gold standard time
          const actualTimes = [] // total actual time
          const lookup = {}

          // group our time data by date in a lookup
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < services_performed.length; i++) {
            const service = services_performed[i]
            const key = moment(service.submitted_at).format('MMM D')
            if (!lookup[key]) {
              lookup[key] = {}
              lookup[key].actualTimes = []
              lookup[key].gst = []
              lookup[key].gst.push(moment.duration(service.gold_standard_time).asMinutes())
              lookup[key].actualTimes.push(moment.duration(service.actual_time_spent).asMinutes())
            } else {
              lookup[key].gst.push(moment.duration(service.gold_standard_time).asMinutes())
              lookup[key].actualTimes.push(moment.duration(service.actual_time_spent).asMinutes())
            }
          }

          // populate the total actual time spent and total gold standard time arrays
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < Object.keys(lookup).length; i++) {
            const key = Object.keys(lookup)[i]
            actualTimes.push(lookup[key].actualTimes.reduce((a, b) => a + b, 0))
            gst.push(lookup[key].gst.reduce((a, b) => a + b, 0))
          }

          options = {
            lineAreaChartSpline: {
              series: [{
                name: 'Gold Standard Time',
                data: gst,
              }, {
                name: 'Actual Time Spent',
                data: actualTimes,
              }],
              chartOptions: {
                dataLabels: {
                  enabled: false,
                },
                // stroke: {
                //   curve: 'smooth'
                // },
                colors: this.themeColors,
                xaxis: {
                  // type: 'datetime',
                  categories: xAxis,
                },
                yaxis: {
                  title: {
                    text: 'Minutes',
                  },
                },
                tooltip: {
                  x: {
                    format: 'dd/MM/yy HH:mm',
                  },
                },
              },
            },
          }
        }
      } catch (e) {
        console.debug(`Could not fetch gold standard time data in GoldStandardTime.vue ${e.message}`)
      }
      return options
    },
  },
  methods: {
    getTotalGoldStandardTime() {
      let output = moment().startOf('day').seconds(0).format('HH:mm:ss')
      try {
        const { services_performed } = this.currentDashboardAnalytics
        if (services_performed.length > 0) {
          const gst = services_performed.map(g => moment.duration(g.gold_standard_time).asSeconds())
          const sum = gst.reduce((a, b) => a + b, 0)
          output = moment().startOf('day').seconds(sum).format('HH:mm:ss')
        }
      } catch (e) {
        console.debug(`Error in getTotalGoldStandardTime: ${e.message}`)
      }
      return output
    },
    getTotalActualTime() {
      let output = moment().startOf('day').seconds(0).format('HH:mm:ss')
      try {
        const { services_performed } = this.currentDashboardAnalytics
        if (services_performed.length > 0) {
          const ast = services_performed.map(a => moment.duration(a.actual_time_spent).asSeconds())
          const sum = ast.reduce((a, b) => a + b, 0)
          output = moment().startOf('day').seconds(sum).format('HH:mm:ss')
        }
      } catch (e) {
        console.debug(`Error in getTotalActualTime: ${e.message}`)
      }
      return output
    },
    unitsOfTime(timestamp, mins = null) {
      let minutes
      if (mins) {
        minutes = mins
      } else {
        minutes = moment.duration(timestamp).asMinutes()
      }
      const cursor = Math.floor(minutes / 15)
      let units = 0
      if (cursor === 0) {
        if (minutes <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      } else {
        units = cursor
        const diff = minutes - (15 * cursor)
        if (diff <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      }
      return units
    },
    unitsOfTimeFormatted(timestamp) {
      const units = this.unitsOfTime(timestamp)
      if (units <= 1) {
        return `${units} unit`
      }

      return `${units} units`
    },
  },
}
</script>
