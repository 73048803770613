<template>
  <b-card
    text-variant="center"
    body-class="align-middle"
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text>
    </b-card-header>
    <b-card-body class="align-middle">
      <b-row align-h="center">
        <b-col
          cols="12"
          align-self="center"
        >
          <div
            v-if="performance"
            class="chart"
          >
            <b-avatar
              size="120"
              :variant="performance.colour"
            >
              <feather-icon
                size="120"
                :icon="performance.icon"
              />
            </b-avatar>
            <h1 class="mt-1">
              {{ performance.grade }}
            </h1>
            <b-card-title class="mt-5">
              Performance is assessed on gold standard time vs actual time spent
            </b-card-title>
          </div>
          <div v-else>
            <b-skeleton-img />
            <b-card-text class="mt-2">
              Awaiting data...
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol, BSkeletonImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
    BSkeletonImg,
  },
  props: {
    title: {
      type: String,
      default: 'Your Performance Rating',
      required: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('statistics', ['currentDashboardAnalytics']),
    performance() {
      let performance = null
      try {
        const grade = this.currentDashboardAnalytics.performance[0].performance_grade
        if (grade) {
          performance = {}
          if (grade <= 100) {
            performance.grade = 'Excellent'
            performance.colour = 'success'
            performance.icon = 'SmileIcon'
          } else if (grade > 100 && grade <= 140) {
            performance.grade = 'Good'
            performance.colour = 'primary'
            performance.icon = 'SmileIcon'
          } else if (grade > 140 && grade <= 170) {
            performance.grade = 'Satisfactory'
            performance.colour = 'warning'
            performance.icon = 'MehIcon'
          } else if (grade > 170) {
            performance.grade = 'Need to re-evaluate'
            performance.colour = 'danger'
            performance.icon = 'FrownIcon'
          }
        }
      } catch (e) {
        console.debug(`Error calculating performance in PerformanceRating.vue: ${e.message}`)
      }
      return performance
    },
  },
}
</script>
