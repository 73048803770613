<template>
  <b-card
    text-variant="center"
  >
    <b-card-body>
      <b-row align-h="center">
        <b-col cols="12">
          <b-img
            :src="require('@/assets/images/illustration/snooze.svg')"
            fluid
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-5"
        >
          <b-card-title>Nothing to see here yet 🙁. Patiently waiting for your data ...</b-card-title>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardText, BCardHeader, BCardTitle, BCardBody, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
}
</script>
